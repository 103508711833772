<template>
  <v-card class="transparent mt-4 pa-2 elevation-0 user-details">
    <v-card-title>
      <v-row>
        <v-col cols="4" class="flex-col">
          <div class="user-type-img control">
            <v-avatar
              class="profile"
              color="grey"
              size="164"
              tile
              v-if="value.profile"
            >
              <v-img
                :style="{
                  backgroundColor:
                    value.profile.avatar && value.profile.avatar.url
                      ? 'white'
                      : value.profile.color,
                }"
                :src="
                  value.profile.avatar && value.profile.avatar.url
                    ? value.profile.avatar.url
                    : value.profile.avatarURL
                "
                :lazy-src="
                  value.profile.avatar && value.profile.avatar.url
                    ? value.profile.avatar.url
                    : value.profile.avatarURL
                "
              ></v-img>
              <div
                class="user-type"
                :class="{
                  employee: value.employee,
                  customer: value.customer,
                }"
              >
                <span class="user-type-label" v-if="value.employee">
                  EMPLOYEE</span
                >
                <span class="user-type-label" v-if="value.customer">
                  CUSTOMER</span
                >
              </div>
            </v-avatar>
            <span class="user-name" v-if="value.profile">
              {{ value.profile.name }}
            </span>
            <span class="employee-title" v-if="value.employee">
              {{ value.employee.title }}
            </span>
            <span class="last-login"> {{ formatDate(value.last_login) }} </span>
          </div>
        </v-col>
        <v-col cols="8" class="flex-col">
          <v-card-text>
            <v-row class="justify-space-between">
              <v-col cols="12">
                <v-row align="center" class="mx-0"> </v-row>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      link
                      v-if="value.profile"
                      v-bind="attrs"
                      v-on="on"
                      @click.prevent.stop="copy(value.profile.work_email)"
                    >
                      <v-list-item-title>{{
                        value.profile.work_email
                      }}</v-list-item-title>

                      <v-list-item-icon>
                        <v-icon>mdi-content-copy</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>

                <v-list-item link>
                  <v-list-item-title>{{ value.email }}</v-list-item-title>

                  <v-list-item-icon>
                    <v-icon>mdi-content-copy</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item v-if="value.settings.timezone" link>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      value.settings.timezone.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      value.settings.timezone.code
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-map-clock </v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item v-if="value.settings.country" link>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      value.settings.country.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >ISO: {{ value.settings.country.iso2 }}/{{
                        value.settings.country.iso3
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      size="24"
                      :style="{ width: '24px' }"
                      color="primary"
                    >
                      {{
                        `fi fi-${String(
                          value.settings.country.iso2
                        ).toLowerCase()}`
                      }}
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="ma-4"></v-divider>

    <v-slide-group
      class="ml-2 my-2"
      center-active
      show-arrows
      v-if="value.userRoles && value.userRoles.length"
    >
      <v-slide-item
        v-for="role in value.userRoles.slice(0, 3)"
        :key="role.id"
        v-slot="{ toggle }"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="ma-2"
              dark
              label
              text-color="white"
              @click="toggle"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-label </v-icon>
              {{ role.role.name }}
            </v-chip>
          </template>
          <span>{{ role.role.description }}</span>
        </v-tooltip>
      </v-slide-item>
    </v-slide-group>
    <v-card-text>
      <v-slide-group
        v-model="tagIndex"
        class="my-4"
        center-active
        show-arrows
        v-if="value.tags && value.tags.length"
      >
        <v-slide-item
          v-for="item in value.tags"
          :key="item.tag.id"
          v-slot="{ toggle }"
        >
          <v-chip
            class="ma-2"
            :color="item.tag.color"
            @click="toggle"
            label
            text-color="white"
          >
            <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
            {{ item.tag.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>
    </v-card-text>
  </v-card>
</template>
    
    
<script>
import { FormatterHelper } from "../../../helpers/formatter.helper";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {},
  data() {
    return {
      formatDate: FormatterHelper.formatDate,

      tagIndex: null,
    };
  },
  computed: {},
  async created() {},
  methods: {},
  watch: {},
};
</script>


<style lang="scss" scoped>
.user-details {
  .user-type-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-name {
    text-transform: uppercase;

    margin-top: 8px;
  }

  .last-login {
    font-size: 12px;
    margin-top: 8px;
    color: #cfd8dc !important;
  }

  .employee-title {
    font-size: 16px;
    margin-top: 8px;
    color: #cfd8dc !important;
  }
  .user-type {
    position: absolute;
    right: -45px;
    top: 11px;
    width: 140px;
    transform: rotate(45deg);
    height: 28px;
    padding: 4px;

    &.employee {
      background-color: #035f9d;
    }
    &.customer {
      background-color: #a71a47;
    }
    .user-type-label {
      left: 0;
      font-size: 12px;
      font-weight: 700;
    }
  }
}
</style>