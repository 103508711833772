<template>
  <AView
    :value="value"
    :actions="actions"
    :active="user.id === activeUserId"
    :name="user.name"
    ref="user-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onSettings="onSettings"
  >
    <template v-slot:content="{}">
      <UserDetailsVue :value="user"></UserDetailsVue>
    </template>
  </AView>
</template>
  
  
  <script>
import { mapGetters, mapState } from "vuex";
import UserDetailsVue from "../../../../../../components/wad/organisms/users/UserDetails.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    UserDetailsVue,
  },
  data: () => ({
    tagIndex: null,
    user: {},
    actions: [
      {
        name: "Save",
        description: "Will save changes",
        icon: "mdi-check",
        color: "#23842a",
        on: "onSave",
      },
      {
        name: "Close",
        description: "Will clear the form",
        icon: "mdi-close",
        color: "#0e1721",
        on: "onClear",
      },
      {
        name: "Settings",
        description: "Change General configurations",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onSettings",
      },
    ],
  }),
  computed: {
    ...mapState("UserStore", ["activeUserId", "displayedUsers"]),
    ...mapGetters("UserStore", ["userById"]),
  },
  async created() {
    await this.$store.dispatch("UserStore/GetUser", {
      id: this.value.relation.params.userId,
    });

    this.user = this.userById(this.value.relation.params.userId);
  },
  methods: {
    onHover() {
      this.$store.commit(
        "UserStore/setActiveId",
        this.value.relation.params.userId
      );
    },
    onUserClick(user) {
      this.$store.commit("UserStore/setActiveId", user.id);
    },
    onSettings() {
      this.$store.dispatch("UserStore/openUserGeneralEditor", {
        from: this.value,
        user: this.user,
      });
    },
    onUserRemove() {},
  },
  watch: {
    displayedUsers: {
      async handler(newVal) {
        if (newVal && !newVal.IN_PROGRESS) {
          //   //   this.user = Object.assign(
          //   //     this.user,
          //   this.user = this.userById(this.value.relation.params.userId);
          //   //   );

          this.user = Object.assign(
            this.user,
            this.userById(this.value.relation.params.userId)
          );
        }
      },
      deep: true,
    },
  },
};
</script>